<template>
  <p>HOME</p>
</template>

<script>
export default {
  name: "Home",
  data: () => ({}),
  computed: {
    token() {
      return this.$store.state.gbaToken;
    },
  },
  components: {},
  mounted() {
    if (this.token) {
      //this.$router.push("/orders");
      if (this.$store.state.gbaUser.indexOf("partner") > -1)
        this.$router.push("/partner-orders");
      else this.$router.push("/supplier-orders");
    } else {
      this.$router.push("/login");
    }
  },
};
</script>
